import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import {GiDragonSpiral } from "react-icons/gi"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: NICHTS GEFUNDEN" />
    <div className="display-1 text-center">
      <AniLink 
        cover
        to="/"
        bg="#0099FF"><GiDragonSpiral /></AniLink></div>
    <br />
    <h1>404 - NICHTS GEFUNDEN</h1>
    <blockquote className="blockquote text-right">
      <p class="mb-0">Du hast einen von vielen Wegen gefunden, welcher nicht funktioniert.
      </p>
      <footer className="blockquote-footer"><cite title="Quelle">Jemand Irgendwann</cite></footer>
    </blockquote>
    <p className="text-center"><AniLink cover bg="#0099FF" to="/">Folge dem <GiDragonSpiral />, er kennt den Weg.</AniLink></p>

  </Layout>
)

export default NotFoundPage
